.Loading {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 150px;
  background: #f1f1f1;
  border-radius: 0px;
  overflow: hidden;
}

.LoadingNotStarted {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 150px;
  background: #f1f1f1;
  border-radius: 0px;
  overflow: hidden;
}

.LoadingFinished {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 150px;
  background: #22c38e;
  border-radius: 0px;
  overflow: hidden;
}

.Loading:after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  animation: load 2s infinite;
}

@keyframes load {
  0% {
    height: 0;
    background: #0078d2;
  }
  
  100% {
    height: 100%;
    background: #0078d2;
  }
}