.ReactSwipeButton {
    float: left;
    width: 100%;
    height: 50px;
    position: relative;
  }
  .ReactSwipeButton,
  .ReactSwipeButton * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    not supported by any browser */
  }
  .rsbContainer {
    float: left;
    width: 100%;
    height: 100%;
    background: #0078d2;
    border-radius: 50px;
    position: relative;
    /* box-shadow: inset 1px 1px 4px rgba(0,0,0,0.1); */
    overflow: hidden;
  }
  .rsbContainerUnlocked {
    width: 50% !important;
    margin-left: 25%;
    transition: 0.5s;
    cursor: default;
  }
  .rsbContainerUnlocked .rsbcSlider {
    left: 100% !important;
    cursor: default;
    pointer-events: none;
  }
  .rsbContainerUnlocked .rsbcSliderArrow {
    transition: 0.5s;
    margin-right: -60px;
  }
  .rsbContainerUnlocked .rsbcSliderCircle {
    transition: 0.5s;
    margin-right: -60px;
  }
  .rsbcSlider {
    float: left;
    width: 100%;
    position: absolute;
    height: 48px;
    top: 0;
    left: 50px;
    margin-left: -100%;
    /* background: #0078d2; */
    border-radius: 25px;
    z-index: 1;
    /* box-shadow: 1px 1px 5px rgba(0,0,0,0.3); */
    cursor: pointer;
  }

  .rsbcSliderDisabled {
    pointer-events: none;
    opacity: 0.7;
  }

  .rsbcSliderText {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    line-height: 50px;
    text-align: center;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    letter-spacing: 2px;
    color: #fff;
    font-size: 13px;
  }
  .rsbcSliderArrow {
    float: left;
    position: absolute;
    transform: rotate(45deg);
    border: 2px solid #0078d2;
    height: 8px;
    width: 8px;
    top: 50%;
    right: 22px;
    margin-top: -6px;
    border-left-color: transparent;
    border-bottom-color: transparent;
    transform-origin: center;
    z-index: 1;
  }

  .rsbcProgressSpin {
    /* float: left; */
    position: absolute;
    top: 11px;
    right: 11px;
    /* margin-top: -6px; */
    /* right: 50%; */
    /* top: 50%; */
    /* transform: translate(-50%, -50%); */
    /* transform-origin: center; */
    z-index: 1;
  }


  .rsbcSliderCircle {
    position: absolute;
    right: 3px;
    background: #ffffff;
    /* border: 5px solid #157946; */
    top: 0px;
    margin-top: 4px;
    height: 42px;
    width: 42px;
    border-radius: 100%;
  }
  .rsbcText {
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 0px;
    font-size: 16px;
    color: #ffffff;
  }  