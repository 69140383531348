.collapseGroup {
    :global(.CollapseIcon) {
        margin-right: var(--space-l);
    }
    :global(.Collapse-Content) {
        padding: 0;
    }
}

.bottomSheetHeader {
    [data-rsbs-header] {
        padding: 0px;
        box-shadow: none;
    }
}