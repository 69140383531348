.registerPlate {
    padding-left: 6px !important;
    padding-right: 6px !important;
    height: 26px;
    color: black !important;
    width: fit-content;
    border: 1px;
    border-color: gray;
    border-radius: 4px;
    border-style: solid;
    background-color: transparent;
}