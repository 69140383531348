.container {
    display: flex;
  
    &.size-2xs {
      gap: var(--space-2xs);
    }
    &.size-xs {
      gap: var(--space-xs);
    }
    &.size-s {
      gap: var(--space-s);
    }
    &.size-m {
      gap: var(--space-m);
    }
    &.size-l {
      gap: var(--space-l);
    }
    &.size-xl {
      gap: var(--space-xl);
    }
    &.size-2xl {
      gap: var(--space-2xl);
    }
  
    &.direction-horizontal {
      flex-direction: row;
  
      &.align-start {
        align-items: flex-start;
      }
      &.align-center {
        align-items: center;
      }
      &.align-end {
        align-items: flex-end;
      }
    }
    &.direction-vertical {
      flex-direction: column;
    }
  
    &.wrap {
      flex-wrap: wrap;
    }
  
    &.spaceBetween {
      justify-content: space-between;
    }
  }
  